.overlay {
  color: #fff;
  position: absolute;
  z-index: 12;
  top: 30%;
  left: 0;
  width: 100%;
  text-align: right;
}

.carousel-control-prev-icon {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.carousel-control-next-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.carousel-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}

.carousel-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 12rem 0;
  position: relative;
  height: 720px;
}

.call-to-action-container {
  display: inline-block;
  position: relative;
  /*@keyframes zoom-in {
  	0% {
  		transform: scale(0.0);
  		opacity: 0;
  	}

  	75% {
  		transform: scale(1.25);
  		opacity: 1;
  	}

  	100% {
  		transform: scale(1.0);
  		opacity: 1;
  	}
  }*/
  /*@keyframes animate-top {
  	0% {
  		transform: translateX(100%);
  	}

  	100% {
  		transform: translateX(-100%);
  	}
  }

  @keyframes animate-right {
  	0% {
  		transform: translateY(100%);
  	}

  	100% {
  		transform: translateY(-100%);
  	}
  }

  @keyframes animate-bottom {
  	0% {
  		transform: translateX(-100%);
  	}

  	100% {
  		transform: translateX(100%);
  	}
  }

  @keyframes animate-left {
  	0% {
  		transform: translateY(-100%);
  	}

  	100% {
  		transform: translateY(100%);
  	}
  }*/
}
.call-to-action-container .call-to-action-button {
  padding: 18px 24px;
  display: inline-block;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 6px;
  background-color: rgb(34, 107, 158);
  color: #ffffff;
  font-weight: 700;
  overflow: hidden;
  /*span {
  	position: absolute;

  	&:nth-child(1) {
  		top: 0px;
  		left: 0px;
  		width: 100%;
  		height: 4px;
  		background: linear-gradient(to left, rgba($gradient-color, 0), $gradient-color);
  		animation: 2s animate-top linear infinite;
  	}

  	&:nth-child(2) {
  		top: 0px;
  		right: 0px;
  		height: 100%;
  		width: 4px;
  		background: linear-gradient(to top, rgba($gradient-color, 0), $gradient-color);
  		animation: 2s animate-right linear -1s infinite;
  	}

  	&:nth-child(3) {
  		bottom: 0px;
  		left: 0px;
  		width: 100%;
  		height: 4px;
  		background: linear-gradient(to right, rgba($gradient-color, 0), $gradient-color);
  		animation: 2s animate-bottom linear infinite;
  	}

  	&:nth-child(4) {
  		top: 0px;
  		left: 0px;
  		height: 100%;
  		width: 4px;
  		background: linear-gradient(to bottom, rgba($gradient-color, 0), $gradient-color);
  		animation: 2s animate-left linear -1s infinite;
  	}
  }*/
}
.call-to-action-container .call-to-action-button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*section.home-banner {
	padding: 0;
	margin: 0;

	.banner-wrapper {
		//background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/images/CURE Home Images/14331_CURE_Web Banner_Images_1.jpg');
		//background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/images/CURE Home Images/14331_CURE_Web Banner_Images_1.jpg');
		//background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/images/Home Carousel Images/gettyimages-880723228-612x612.jpg');
		//background-repeat: no-repeat;
		//background-position: top right;
		//background-size: cover;
		//padding: 12rem 0;
		//position: relative;

		.call-to-action-container {
			display: inline-block;
			position: relative;
			//opacity: 0;
			//animation: 0.75s zoom-in 1s linear forwards;

			.call-to-action-button {
				padding: 18px 24px;
				display: inline-block;
				font-size: 20px;
				text-transform: uppercase;
				letter-spacing: 1px;
				border-radius: 6px;
				//border: 3px solid $theme-color-primary;
				background-color: $site-color-action-primary;
				color: $site-color-text-light;
				font-weight: 700;
				overflow: hidden;

				$gradient-color: $site-color-action-alternate; //white; //#5a1a19;
				&::before {
					content: '';
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					//background-color: red;
					opacity: 0;
					//transition: .2s opacity ease-in-out;
				}

				span {
					position: absolute;

					&:nth-child(1) {
						top: 0px;
						left: 0px;
						width: 100%;
						height: 4px;
						background: linear-gradient(to left, rgba($gradient-color, 0), $gradient-color);
						animation: 2s animate-top linear infinite;
					}

					&:nth-child(2) {
						top: 0px;
						right: 0px;
						height: 100%;
						width: 4px;
						background: linear-gradient(to top, rgba($gradient-color, 0), $gradient-color);
						animation: 2s animate-right linear -1s infinite;
					}

					&:nth-child(3) {
						bottom: 0px;
						left: 0px;
						width: 100%;
						height: 4px;
						background: linear-gradient(to right, rgba($gradient-color, 0), $gradient-color);
						animation: 2s animate-bottom linear infinite;
					}

					&:nth-child(4) {
						top: 0px;
						left: 0px;
						height: 100%;
						width: 4px;
						background: linear-gradient(to bottom, rgba($gradient-color, 0), $gradient-color);
						animation: 2s animate-left linear -1s infinite;
					}
				}
			}
		}

		@keyframes zoom-in {
			0% {
				transform: scale(0.0);
				opacity: 0;
			}

			75% {
				transform: scale(1.25);
				opacity: 1;
			}

			100% {
				transform: scale(1.0);
				opacity: 1;
			}
		}

		@keyframes animate-top {
			0% {
				transform: translateX(100%);
			}

			100% {
				transform: translateX(-100%);
			}
		}

		@keyframes animate-right {
			0% {
				transform: translateY(100%);
			}

			100% {
				transform: translateY(-100%);
			}
		}

		@keyframes animate-bottom {
			0% {
				transform: translateX(-100%);
			}

			100% {
				transform: translateX(100%);
			}
		}

		@keyframes animate-left {
			0% {
				transform: translateY(-100%);
			}

			100% {
				transform: translateY(100%);
			}
		}
	}
}*/
a.read-more {
  margin-top: 20px;
  font-weight: 600;
  color: rgb(34, 107, 158);
}
a.read-more:hover {
  color: rgb(248, 141, 42);
  text-decoration: underline;
  cursor: pointer;
}

div.watermark {
  position: absolute;
  padding: 5px;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  font-size: 320px;
}

div.watermark.small {
  font-size: 110px;
}

div.watermark.right {
  text-align: right;
}

div.watermark.center {
  text-align: center;
}

div.watermark.bottom {
  bottom: 0;
}

.theme.list-group > .list-group-item-action.active {
  background-color: rgb(34, 107, 158) !important;
  color: #ffffff;
}
.theme.list-group > .list-group-item-action.active h1, .theme.list-group > .list-group-item-action.active h2, .theme.list-group > .list-group-item-action.active h3, .theme.list-group > .list-group-item-action.active h4, .theme.list-group > .list-group-item-action.active h5, .theme.list-group > .list-group-item-action.active h6 {
  color: #ffffff;
}