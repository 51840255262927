section.home-banner {
  padding: 0;
  margin: 0;
}
section.home-banner .banner-wrapper {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url("/images/stock1.jpg");
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("/images/stock1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 12rem 0;
  position: relative;
}