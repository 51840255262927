a {
  color: cornflowerblue;
  text-decoration: underline;
}

#res-site-map {
  position: relative;
  height: 500px;
  border-radius: 4px;
  border: 2px solid rgb(130, 175, 95);
}
#res-site-map .marker.blue path {
  fill: #216CA0;
}
#res-site-map .marker.green path {
  fill: #8FC169;
}
#res-site-map .marker.orange path {
  fill: #ECB86B;
}
#res-site-map .marker.red path {
  fill: #F1778C;
}
#res-site-map .marker.teal path {
  fill: #0CA9C2;
}
#res-site-map .marker:after {
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 2px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bolder;
  color: white;
}
#res-site-map .marker.lead:after {
  content: "L";
}
#res-site-map .marker.field:after {
  content: "F";
}
#res-site-map .marker.partner:after {
  content: "P";
}
#res-site-map .filter-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  z-index: 1000;
}
#res-site-map .card .card-header.blue {
  background-color: #216CA0;
  border-color: #216CA0;
}
#res-site-map .card .card-header.green {
  background-color: #8FC169;
  border-color: #8FC169;
}
#res-site-map .card .card-header.orange {
  background-color: #ECB86B;
  border-color: #ECB86B;
}
#res-site-map .card .card-header.red {
  background-color: #F1778C;
  border-color: #F1778C;
}
#res-site-map .card .card-header.teal {
  background-color: #0CA9C2;
  border-color: #0CA9C2;
}

.side-panel {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1100;
  top: 0;
  right: 0;
  overflow: hidden;
  padding: 10px;
  margin: 10px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.side-panel .close {
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
}
.side-panel .content {
  padding: 40px 10px;
  overflow-y: scroll;
  height: 100%;
}
.side-panel .content .card .card-header.blue {
  background-color: #216CA0;
  border-color: #216CA0;
}
.side-panel .content .card .card-header.green {
  background-color: #8FC169;
  border-color: #8FC169;
}
.side-panel .content .card .card-header.orange {
  background-color: #ECB86B;
  border-color: #ECB86B;
}
.side-panel .content .card .card-header.red {
  background-color: #F1778C;
  border-color: #F1778C;
}
.side-panel .content .card .card-header.teal {
  background-color: #0CA9C2;
  border-color: #0CA9C2;
}
.side-panel .content .card .card-body.FES-4 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.75)), color-stop(50%, rgba(255, 255, 255, 0.9)), to(rgba(255, 255, 255, 0.9))), url("/images/unc_seal.png") no-repeat;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 100%), url("/images/unc_seal.png") no-repeat;
  background-size: cover;
}

.side-panel.open {
  width: 50%;
}

.custom-control-input:checked ~ .custom-control-label.blue::before {
  background-color: #216CA0;
  border-color: #216CA0;
}

.custom-control-input:checked ~ .custom-control-label.green::before {
  background-color: #8FC169;
  border-color: #8FC169;
}

.custom-control-input:checked ~ .custom-control-label.orange::before {
  background-color: #ECB86B;
  border-color: #ECB86B;
}

.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: #F1778C;
  border-color: #F1778C;
}

.custom-control-input:checked ~ .custom-control-label.teal::before {
  background-color: #0CA9C2;
  border-color: #0CA9C2;
}